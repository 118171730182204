<!-- src/components/Feed.vue -->
<template>
  <v-container id="feed">
    <v-row >
      <v-col cols="12" md="3" v-if="checkDisplay">
         <v-row no-gutters>
        
          <v-col cols="12" >
            <CommunityList @select="selectCommunity"  
              :communities="isUserAdminOrSU? availableCommunities: userCommunities"
              :availableUsers="availableUsers"
              :isUserAdminOrSU="isUserAdminOrSU"
              :load_status="loading"
              :selected="selectedCommunity"
              @update="updateCommunity"
              @delete="deleteCommunity"
              @create="createCommunity"
              ref="communityList"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" :offset-md="checkDisplay?0:3">      

        <!-- Page content -->
        <v-row no-gutters v-if="!loading">


          <v-col cols="12"  class="mb-4">
             <v-toolbar class="glass" v-if="availableCommunities.length>1">
              <v-toolbar-title>
                  <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title class="text-h6"> {{ availableCommunities.find(c => c.id === selectedCommunity)?.name || "General" }}</v-list-item-title>
                <v-list-item-subtitle>
                  <!-- selected commmunity members number --> 
                    {{ availableCommunities.find(c => c.id === selectedCommunity)?.members && availableCommunities.find(c => c.id === selectedCommunity)?.members.length +' Miembros' || ''}} 
                </v-list-item-subtitle>
              </v-list-item-content>
             </v-list-item>

              </v-toolbar-title>

              <v-spacer/>



            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn icon v-if="selectedCommunity!='general'" @click="editCommunity" v-on="on">
              <v-icon>
                mdi-cog
              </v-icon>
            </v-btn>
              </template>
              <span>
                Editar Comunidad
              </span>
            </v-tooltip>
            
           
            </v-toolbar>
            <v-divider v-if="availableCommunities.length>1" />

            <v-tabs :show-arrows="false" >
              <v-tab>
                <v-icon left>
                  mdi-newspaper
                </v-icon>
                Recientes
              </v-tab>

               <v-tab v-if="isUserAdminOrSU" disabled>
                <v-icon left>
                  mdi-calendar
                </v-icon>
                Agendar Publicación
              </v-tab>
            </v-tabs>


            <PostForm v-if="isUserAdminOrSU"
              :availableCommunities="availableCommunities"
              @create="createPost"

              :selectedCommunity="selectedCommunity"
            />
          </v-col>

          <v-col cols="12">
            <v-row no-gutters>
              <v-col
                cols="12"
                class="mb-4"
                v-for="(post, index) in sortedPosts"
                :key="post.id"
              >
                <PostForm  :selectedCommunity="selectedCommunity"
                  v-if="selectedPost && selectedPost.id === post.id"
                  :value="selectedPost"
                  :availableCommunities="availableCommunities"
                  @update="updatePost"
                  @cancelEdit="cancelEdit"
                />

                <PostDisplay
                  :key="post.id + 'display'"
                  v-if="
                    !selectedPost ||
                    (selectedPost && selectedPost.id !== post.id)
                  "
                  :value="post"
                  @openEdit="selectedPost = { ...post }"
                  @update="updatePost"
                  @delete="deletePost"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- Skeletons -->
        <v-scroll-x-transition hide-on-leave mode="in-out"  >
          <div v-show="loading">
             <v-skeleton-loader v-show="loading"
            class="mb-4"
            type="card,actions"
            v-if="isUserAdminOrSU"
          />
          <v-divider v-if="isUserAdminOrSU" />
          <v-skeleton-loader class="mb-4" type="article,actions" />
          <v-skeleton-loader class="mb-4" type="article,actions" />
          <v-skeleton-loader class="mb-4" type="article,actions" />
             </div>
         
        </v-scroll-x-transition>

        <!-- Empty page -->
        <v-scroll-x-transition hide-on-leave mode="in-out">


           <!--   <v-img
        src="@/assets/posts.svg"
        height="200px"
        class="white--text align-end rounded overflow-hidden"
        gradient="to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.7)"
      >
       
      </v-img> -->



        <v-card class="py-10"   v-show="!loading && !posts.length">
          <v-cart-text class="text-center">
            <p><v-icon large >mdi-newspaper</v-icon></p>
            <h2 class="text-h6 mt-5 font-weight-light" >  {{
                isUserAdminOrSU
                  ? "No hay publicaciones, crea una nueva"
                  : "No hay publicaciones"
              }}</h2>
          
          </v-cart-text>
        </v-card>

<!--           <v-alert type="info" color="primary" text border="left" class="mt-4" >
            {{
              isUserAdminOrSU
                ? "No hay publicaciones, crea una nueva"
                : "No hay publicaciones"
            }}
          </v-alert> -->
        </v-scroll-x-transition>
      </v-col>

    
    
    </v-row>
  </v-container>
</template>

<script>
import {
  collection,
  getDocs,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { ref as storageRef, getStorage, deleteObject } from "firebase/storage";
import PlanProgress from "@/components/PlanProgress.vue";
import PostForm from "@/components/feed/form/PostForm.vue";
import PostDisplay from "@/components/feed/post/PostDisplay.vue";
import CommunityForm from "@/components/community/CommunityForm.vue";
import CommunityList from "@/components/community/CommunityList.vue";

export default {
 

  name: "Feed",

  components: {
    PlanProgress,
    PostForm,
    PostDisplay,
    CommunityForm,
    CommunityList,
  },

  data() {
    return {
      posts: [],
      loading: true,
      availableUsers: [],
      availableCommunities: [],
      userCommunities: [],
      selectedPost: null,
      selectedCommunity: '',
    };
  },

  async mounted() {
    if (this.isUserAdminOrSU) {
      await this.fetchAvailableUsers();
      await this.fetchAvailableCommunities();
    }
    await this.fetchUserCommunities();
    await this.fetchPosts('general');
  },

  computed: {
    isUserAdminOrSU() {
      const role = this.$store.state.Auth.token.claims.type;
      return role && ["admin", "superuser"].includes(role);
    },
    sortedPosts() {
      return this.posts.sort((a, b) => b.created.date - a.created.date);
    },
    checkDisplay(){
      return this.isUserAdminOrSU? true: this.userCommunities.length > 1;
    }
  },

  methods: {
    async fetchPosts(communityId) {
      if(communityId === this.selectedCommunity){
        return;
      }

      this.loading = true;
      const db = getFirestore();
      this.posts = [];
      
      // Fetch posts for the specified communityId
      const q = query(
      collection(db, "posts"),
      where("communityId", "==", communityId)
      );
      const querySnapshot = await getDocs(q);

      let posts = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      }));

      // Fetch display names for post authors
      const userIds = [...new Set(posts.map((post) => post.created.user))];
      const userNames = {};
      for (const userId of userIds) {
      const userDoc = doc(db, "users", userId);
      const userSnap = await getDoc(userDoc);
      userNames[userId] = userSnap.data()
        ? userSnap.data().displayName
        : "Usuario eliminado";
      }

      // Add displayName and communityName to each post
      posts = posts.map((post) => ({
      ...post,
      created: {
        ...post.created,
        displayName: userNames[post.created.user],
      },
      communityName:
        this.userCommunities.find((c) => c.id === post.communityId)?.name ||
        "General",
      }));

      this.selectedCommunity = communityId
      this.posts = posts;
      this.loading = false;
    },

    async fetchAvailableUsers() {
      const db = getFirestore();
      const q = query(collection(db, "users"), where("disabled", "==", false));
      const querySnapshot = await getDocs(q);
      let users = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .sort((a, b) => {
          if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
            return -1;
          }
          if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
            return 1;
          }
          return 0;
        });

      this.availableUsers = users;
    },

    async fetchAvailableCommunities() {
      const db = getFirestore();
      const querySnapshot = await getDocs(collection(db, "communities"));
      let communities = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));


      //check if general community exists and add it if it doesn't
      if (!communities.find((c) => c.id === "general")) {
        communities.unshift({ id: "general", name: "General" });
      }

      this.availableCommunities = communities;
    },

    async fetchUserCommunities() {

      const db = getFirestore();
      const userId = this.$store.state.Auth.token.claims.user_id;
      const userDoc = await getDoc(doc(db, "users", userId));
      const userData = userDoc.data();

      let userCommunityIds = userData.enabledCommunities || [];

      // Always include 'general' community
      if (!userCommunityIds.includes("general")) {
        userCommunityIds.unshift("general");
      }

      // Fetch community details
      let communities = [];
      for (const communityId of userCommunityIds) {
        if (communityId === "general") {
          communities.push({ id: "general", name: "General" });
        } else {
          const communityDoc = await getDoc(
            doc(db, "communities", communityId)
          );
          if (communityDoc.exists()) {
            communities.push({
              id: communityDoc.id,
              ...communityDoc.data(),
            });
          }
        }
      }

      this.userCommunities = communities;
    },

    createPost(post) {
      this.posts.unshift(post);
    },

    updatePost(post) {
      const postIndex = this.posts.findIndex((e) => e.id === post.id);
      if (postIndex !== -1) {
        this.$set(this.posts, postIndex, post);
      }

      if (this.selectedPost) {
        this.selectedPost = null;
      }
    },

    deletePost(post) {
      const postIndex = this.posts.findIndex((e) => e.id === post.id);
      if (postIndex !== -1) {
        this.posts.splice(postIndex, 1);
      }
    },

    cancelEdit() {
      this.selectedPost = null;
    },

    createCommunity(community) {
      this.availableCommunities.push(community);

      this.$nextTick(() => {
       this.$refs.communityList.selectCommunity(community);
      });
    },

    updateCommunity(updatedCommunity) {
      const index = this.availableCommunities.findIndex(
        (c) => c.id === updatedCommunity.id
      );
      if (index !== -1) {
        this.$set(this.availableCommunities, index, updatedCommunity);
      }
    },

    deleteCommunity(communityId) {
      const index = this.availableCommunities.findIndex(
        (c) => c.id === communityId
      );
      if (index !== -1) {
        this.availableCommunities.splice(index, 1);
      }

      //select general
      this.fetchPosts('general');
    },

    selectCommunity(community) {
      this.fetchPosts(community.id);
    },

    editCommunity() {
      this.$refs.communityList.editCommunity(
        this.availableCommunities.find((c) => c.id === this.selectedCommunity)
      );
    },
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.85;
  position: absolute;
  width: 100%;
}
</style>

<style>
#feed .v-slide-group__prev{
  display: none !important;
}

#feed .v-slide-group__next{
  display: none !important;
}
</style>
