<!-- src/components/community/CommunityList.vue -->
<template>
  <v-card class="">
    <v-toolbar flat>
        <v-icon left>
          mdi-bullhorn
        </v-icon>


        <span class="text-h6">Comunidades</span>
            <v-spacer/>
                    <CommunityForm ref="communityForm"  v-if="isUserAdminOrSU" :availableUsers="availableUsers" @create="createCommunity" />

        </v-toolbar>
    <v-divider/>

    
    <v-list class="py-0" v-if="$props.communities.length>0" >
      <v-list-item
        v-for="community in $props.communities"
        :key="community.id"
        @click="selectCommunity(community)"
      >
        <v-list-item-content>
          <v-list-item-title> {{ community.name }}  
              
          </v-list-item-title>
         
        </v-list-item-content>
              <v-list-item-avatar size="15" color="primary" v-if="community.id == $props.selected"></v-list-item-avatar>

      </v-list-item>
    </v-list>
        <template v-else>
          <v-skeleton-loader  ton-loader type="list-item" ></v-skeleton-loader>
          <v-skeleton-loader  ton-loader type="list-item" ></v-skeleton-loader>
          <v-skeleton-loader  ton-loader type="list-item" ></v-skeleton-loader>

        </template>
      

    <!-- Dialog to edit community -->
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title>Editar Comunidad</v-card-title>
        <v-card-text v-if="selectedCommunity">
          <v-form ref="editForm" @submit.prevent="updateCommunity">
            <v-text-field
              label="Nombre de la Comunidad" rounded filled class="rounded-lg"
              v-model="selectedCommunity.name"
              :rules="[v => !!v || 'El nombre es requerido']"
            ></v-text-field>
            <v-select
              :items="availableUsers" 
              item-text="displayName"
              item-value="id"
              label="Administrar Miembros"
              v-model="selectedCommunity.members"
              multiple
              chips
              :rules="[v => v.length > 0 || 'Debe seleccionar al menos un usuario']"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
            
            <v-btn @click="confirmDeleteCommunity(selectedCommunity)" text color="error" :loading="loadingDelete">Eliminar</v-btn>
        <v-spacer/>
          <v-btn text @click="editDialog = false">Cancelar</v-btn>
          <v-btn color="success" @click="updateCommunity" :loading="loading">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
  getFirestore,
} from "firebase/firestore";
import { arrayUnion, arrayRemove } from "firebase/firestore";


import CommunityForm from "./CommunityForm.vue";

export default {
  components: {
    CommunityForm,
  },
  name: "CommunityList",
  props: {
    communities: {
      type: Array,
      required: true,
      default: () => [],
    },
    availableUsers: {
      type: Array,
      required: true,
    },
    isUserAdminOrSU: {
      type: Boolean,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editDialog: false,
      selectedCommunity: null,
      loadingDelete: false,
      loading:false,
    };
  },
  mounted(){
    //select the first community
    if(this.$props.communities.length > 0){
      this.selectCommunity(this.$props.communities[0]);
      this.loading = false;
    }
  },
  methods: {
    editCommunity(community) {
      this.selectedCommunity = { ...community }; // Clone the community object
      this.editDialog = true;
    },
    async updateCommunity() {
      if (this.$refs.editForm.validate()) {
        try {
            this.loading = true;
          const db = getFirestore();
          const communityRef = doc(db, "communities", this.selectedCommunity.id);

          // Get the original community data
          const originalCommunityDoc = await getDoc(communityRef);
          const originalCommunity = originalCommunityDoc.data();

          // Update the community in Firestore
          await updateDoc(communityRef, {
            name: this.selectedCommunity.name,
            members: this.selectedCommunity.members,
          });

          // Determine which users have been added or removed
          const originalMembers = originalCommunity.members || [];
          const newMembers = this.selectedCommunity.members;

          const addedMembers = newMembers.filter(
            (userId) => !originalMembers.includes(userId)
          );
          const removedMembers = originalMembers.filter(
            (userId) => !newMembers.includes(userId)
          );

          // Update users' enabledCommunities
          for (const userId of addedMembers) {
            const userRef = doc(db, "users", userId);
            await updateDoc(userRef, {
              enabledCommunities: arrayUnion(this.selectedCommunity.id),
            });
          }

          for (const userId of removedMembers) {
            const userRef = doc(db, "users", userId);
            await updateDoc(userRef, {
              enabledCommunities: arrayRemove(this.selectedCommunity.id),
            });
          }

          // Emit the updated community
          this.$emit("update", { ...this.selectedCommunity });
          this.editDialog = false;
          this.$notify({
            title: "Comunidad actualizada",
            text: "La comunidad ha sido actualizada exitosamente",
            type: "success",
          });
        } catch (error) {
          console.error("Error updating community:", error);
          this.$notify({
            title: "Error",
            text: "Ocurrió un error al actualizar la comunidad.",
            type: "error",
          });
        }
        this.loading = false;
        this.editDialog = false;
      }
    },
    async confirmDeleteCommunity(community) {
      const confirm = await this.$confirm(
        `¿Está seguro de que desea eliminar la comunidad "${community.name}"?`,
        {
          color: "error",
          title: "Eliminar Comunidad",
          buttonTrueText: "Eliminar",
          buttonFalseText: "Cancelar",
        }
      );
      if (confirm) {
        await this.deleteCommunity(community);
      }
    },
    async deleteCommunity(community) {
      try {
        this.loadingDelete = true;
        const db = getFirestore();
        const communityRef = doc(db, "communities", community.id);

        // Delete the community from Firestore
        await deleteDoc(communityRef);

        // Remove community from users' enabledCommunities
        for (const userId of community.members) {
          const userRef = doc(db, "users", userId);
          await updateDoc(userRef, {
            enabledCommunities: arrayRemove(community.id),
          });
        }

        this.$emit("delete", community.id);
        this.$notify({
          title: "Comunidad eliminada",
          text: "La comunidad ha sido eliminada exitosamente",
          type: "success",
        });
      } catch (error) {
        console.error("Error deleting community:", error);
        this.$notify({
          title: "Error",
          text: "Ocurrió un error al eliminar la comunidad.",
          type: "error",
        });
      }
        this.loadingDelete = false;

        this.editDialog = false;


    },
    selectCommunity(community) {
      this.$emit("select", community);
    },
    createCommunity(community) {
      this.$emit("create", community);
    },
  },
};
</script>
